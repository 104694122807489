import React from "react";
import "../App.css";
import Banderas from "../componentes.home/Banderas";
import Header from "../componentes.home/Header";
import JuegoResp from "../componentes.home/JuegoResp";
import NuestroJuego from "../componentes.home/NuestroJuego";
import ProductosP from "../componentes.home/ProductosP";
import Promociones from "../componentes.home/Promociones";
import Socios from "../componentes.home/Socios";
import Tutoriales from "../componentes.home/Tutoriales";
import MetaTags from 'react-meta-tags';
import {metaTags} from "../config/configuraciones"







function Home() {

  return (
    <div className="app">
      <MetaTags>
      <title>{metaTags.home.title}</title>
  <meta name="description" content={metaTags.home.description} />
  <meta charSet={metaTags.generales.charSet} />
  <link rel="icon" href={metaTags.generales.imgLogo} />
  <meta name="viewport" content={metaTags.generales.imgviewport} />
  <meta name="theme-color" content={metaTags.generales.color} />
  <link rel="apple-touch-icon" href={metaTags.generales.imgLogo}/>
  <link rel="manifest" href={metaTags.generales.manifest} />
      </MetaTags>

      <Header /> 
     
      <Promociones />
      <main>
      <ProductosP />
      </main>


      <Tutoriales />
      {/* <Socios />
      <JuegoResp /> */}
    </div>
  );
}

export default Home;
