


export const imagenes = {
    urlImage:'./origo'
}
export const metaTags = {

    generales:{
        imgLogo:`${imagenes.urlImage}/sombrero.png`,
        color:"#000000",
        viewport:"width=device-width, initial-scale=1",
        manifest:"%PUBLIC_URL%/manifest.json",
        charSet:"utf-8"

    },
    bonosHome:{
    title:"Paquetes de bonos con descuento",
    description:"Paquetes de bonos con descuento"
    },
    contacto:{
        title:"Ponete en contacto con el Club Origo - Puntos de canje",
        description:"Ponete en contacto con el Club Origo - Puntos de canje"
        },
        influencer:{
            title:"Influencer",
            description:"buscamos socios alianzas comerciales con influencers."
        },
        comercial:{
            title:"Local comercial",
            description:"buscamos socios para alianzas comerciales con dueños de comercios."
        },
        repre:{
            title:"Representante",
            description:"buscamos socios para trabajo de calidad de representante comercial de las maquinas fisicas tragamonedas de apuestas Mbokajá. representante comercial de la app tragamonedas de apuestas Mbokajá."
        },
        home:{
            title:"Club de apuestas tragamonedas y beneficios donde todas tus jugadas suman puntos para canjear por los productos",
            description:"El club de apuestas y beneficios Donde todas tus jugadas suman puntos para canjear por los productos. Máquinas slot tragamonedas y juegos de azar que más pagan."
        }
        ,
        juegosHome:{
            title:"Los juegos Mbokajá de apuestas son los que mas pagan en el mundo. Slot tragamonedas - Bingo",
            description:"Los juegos Mbokajá de apuestas son los que mas pagan en el mundo. Slot tragamonedas - Bingo - .Todas tus jugadas suman puntos para canjear por los productos.   "
        }
        ,
        login:{
            title:"Inicia Sesión",
            description:"Te regalamos 50 bonos de bienvenida para la app tragamonedas de apuestas Nº1 en premios. Mbokajá es el tragamoedas que mas paga por apuesta."
        }
        ,
        pagaHome:{
            title:"Mbokajá Super Bonus: La app slot tragamonedas de apuestas que más paga",
            description:"Mbokajá: app slot tragamonedas de apuestas que más paga. Todas tus jugadas suman puntos canjeables por productos. Incluye ruleta Mbokajá. Aprobada por Conajzar."
        }
        ,
        promocionesHome:{
            title:"Beneficios, promociones, paquetes de bonos con descuento, ofertas en productos para canjear tus puntos",
            description:"Beneficios, promociones, paquetes de bonos con descuento, ofertas en productos para canjear tus puntos y mas de la maquina slot tragamonedas que mas paga "
        },
        puntosHome:{
            title:"Canjea los puntos de todas tus jugadas por productos y premios del Club Origo",
            description:"Canjea los puntos de todas tus jugadas, sean ganadoras o no, por productos y premios del Club Origo. Celulares, electrodomésticos y un  montón de cosas útiles"
        },
        recuperarHome:{
            title:"Recupera tu usuario y contraseña del Club Origo",
            description:"Recupera tu usuario y contraseña del Club Origo"
        },
        registerHome:{
            title:"Regístrate en el Club Origo",
            description:"Regístrate en el club de apuestas y beneficios donde todas tus jugadas suman puntos para canjear por los productos. Slot tragamonedas y juegos de azar que más pagan."
        },
        sociosHome:{
            title:"Buscamos socios - representante comercial - alianzas comerciales para dueños de comercios - influencers. ",
            description:"Buscamos socios para trabajo de calidad. Ofertas de trabajo de - representante comercial - alianzas comerciales para dueños de comercios - influencers."
        },
        sorteosHome:{
            title:"Sorteos",
            description:"Esos puntos los canjeas por los productos,sorteos y bonos"
        },
        tutorialesHome:{
            title:"Todas las respuestas sobre la app slot tragamonedas de apuestas que mas paga",
            description:"Tutoriales: Todas las respuestas sobre la app slot tragamonedas de apuestas que mas paga. Conviértete en un experto en la maquina tragamonedas mas estratégica."
        },
        notasHome:{
            title:"trucos, consejos y estrategias para ganar en maquinas slots tragamonedas",
            description:"Trucos, consejos y estrategias para ganar en maquinas slots tragamonedas. - App simulador para entrenar en la maquina tragamonedas que mas paga."
        },
        carritoHome:{
            title:"Tu carrito"
        },
        cuentaHome:{
            title:"Editar tu cuenta"
        },
        editcHome:{
            title:"Editar contraseña"
        },
        ticketsHome:{
            title:"Tus Tickets"
        },
        cambiarC:{
            title:"Cambiar contraseña"
        },
        pedidosHome:{
            title:"Tus pedidos"
        }


}

// export default configuraciones;