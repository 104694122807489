import React from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import "../App.css";
import MenuUser from "../userBotones/MenuUser";

function BotonUser(props) {
  // const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  return (
    <React.Fragment>
      {userInfo ? (
        <div>
          <MenuUser />
        </div>
      ) : (
        // <Link to="/">
        //   <button type="button" onClick={handleLogout} className="sesion">

        //     <p>Salir</p><br/>
        //   </button>
        // </Link>

        <Link to="/signin">
          <button type="button" className="sesion">
            <p>Ingresar</p>
          </button>
        </Link>
      )}
    </React.Fragment>
  );
}

export default BotonUser;
