import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Layouts from "./layout/Layouts";
import PrivateRoute from "./routes/PrivateRoute";
import serverComodin from "./config/serverComodin";
import Home from "./screen/Home";
import Spinner from "./componentes/Spinner";
import LoginApp from "./userScreens/LoginApp";
import Descargaf from "./screen/Descargaf";


const NotasIdHome = lazy(() => import('./screen/NotasIdHome'))
const NotasHome = lazy(() => import('./screen/NotasHome'))
const PromocionesScreen = lazy(() => import('./screen/PromocionesScreen'))
//const Home = lazy(() => import('./screen/Home'))
const Error = lazy(() => import('./screen/Error'))
const Notfound = lazy(() => import('./screen/Notfound'))
const JuegosHome = lazy(() => import('./screen/JuegosHome'))
const PromocionesHome = lazy(() => import('./screen/PromocionesHome'))
const TutorialesHome = lazy(() => import('./screen/TutorialesHome'))
const  SorteosHome = lazy(() => import('./screen/SorteosHome'))
const ContactoHome = lazy(() => import('./screen/ContactoHome'))
const SociosHome = lazy(() => import('./screen/SociosHome'))
const FormRepresentante = lazy(() => import('./screen/FormRepresentante'))
const FormLocal = lazy(() => import('./screen/FormLocal'))
const FormInfluencer = lazy(() => import('./screen/FormInfluencer'))
const BonosHome  = lazy(() => import('./screen/BonosHome'))
const LoginHome = lazy(() => import('./screen/LoginHome'))
const RegisterHome = lazy(() => import('./screen/RegisterHome'))
const RecuperarHome = lazy(() => import('./screen/RecuperarHome'))
const GratisHome = lazy(() => import('./screen/GratisHome'))
const PagaHome = lazy(() => import('./screen/PagaHome'))
const PuntosHome = lazy(() => import('./screen/PuntosHome'))
const MicuentaHome = lazy(() => import('./userScreens/MicuentaHome'))
const EditarCuentaHome = lazy(() => import('./userScreens/EditarCuentaHome'))
const EditarContraseñaHome = lazy(() => import('./userScreens/EditarContraseñaHome'))
const PedidosHome = lazy(() => import('./userScreens/PedidosHome'))
const TicketsHome = lazy(() => import('./userScreens/TicketsHome'))
const HomeCarrito = lazy(() => import('./screen/HomeCarrito'))
const ProductIdHome = lazy(() => import('./screen/ProductIdHome'))
const CarritoHome = lazy(() => import('./screen/CarritoHome'))
const BonosCompra = lazy(() => import('./screen/BonosCompra'))



// import Notfound from "./screen/Notfound";
// import JuegosHome from "./screen/JuegosHome";
// import PromocionesHome from "./screen/PromocionesHome";
// import TutorialesHome from "./screen/TutorialesHome";
// import SorteosHome from "./screen/SorteosHome";
// import ContactoHome from "./screen/ContactoHome";
// import SociosHome from "./screen/SociosHome";
// import FormRepresentante from "./screen/FormRepresentante";
// import FormLocal from "./screen/FormLocal";
// import FormInfluencer from "./screen/FormInfluencer";
// import BonosHome from "./screen/BonosHome";
// import LoginHome from "./screen/LoginHome";
// import RegisterHome from "./screen/RegisterHome";
// import RecuperarHome from "./screen/RecuperarHome";
// import GratisHome from "./screen/GratisHome";
// import PagaHome from "./screen/PagaHome";
// import PuntosHome from "./screen/PuntosHome";
// import PrivateRoute from "./routes/PrivateRoute";
// import MicuentaHome from "./userScreens/MicuentaHome";
// import EditarCuentaHome from "./userScreens/EditarCuentaHome";
// import EditarContraseñaHome from "./userScreens/EditarContraseñaHome"
// import PedidosHome from "./userScreens/PedidosHome";
// import TicketsHome from "./userScreens/TicketsHome";
// import HomeCarrito from "./screen/HomeCarrito";
// import ProductIdHome from "./screen/ProductIdHome";
// import CarritoHome from "./screen/CarritoHome";
// import BonosCompra from "./screen/BonosCompra";




class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: false };
  }

  componentDidMount() {
    const API = serverComodin.baseURL + "/paginas/configuraciones";
    fetch(API, {
      method: "GET",
      
    })
    
      .then((response) => response.json())
      
      .then((data) => {
        //console.log("config");

        localStorage.setItem("configServer", JSON.stringify(data.data));

        this.setState({
          status: true,
        });
      })

      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    let loading;
    if (this.state.status === false) {
      loading = <span><Spinner/></span>;
    } else {
      loading = (
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route path="/mbokaja-juegos" component={JuegosHome}></Route>
          <Route path="/promociones" component={PromocionesHome}></Route>
          <Route path="/tutoriales" component={TutorialesHome}></Route>
          <Route path="/sorteos" component={SorteosHome}></Route>
          <Route path="/contacto" component={ContactoHome}></Route>
          <Route path="/socios" component={SociosHome}></Route>
          <Route
            path="/form-representante"
            component={FormRepresentante}
          ></Route>
          <Route path="/form-local" component={FormLocal}></Route>
          <Route path="/form-influencer" component={FormInfluencer}></Route>
          <Route path="/bonos" component={BonosHome}></Route>
          <Route path="/signin" component={LoginHome}></Route>
          <Route path="/register" component={RegisterHome}></Route>
          <Route path="/codigo" component={RecuperarHome}></Route>
          <Route path="/maquina-gratis" component={GratisHome}></Route>
          <Route path="/maquina" component={PagaHome}></Route>
          <Route path="/canjea-tus-puntos" component={PuntosHome}></Route>
          <Route path="/carrito" component={HomeCarrito}></Route>
          <Route path="/payment" component={CarritoHome}></Route>
          <Route path="/products/:id/:name" component={ProductIdHome}></Route>
          <Route path="/pages/:id/:name" component={NotasIdHome}></Route>
          <Route path="/login-app" component={LoginApp}></Route>
          <Route path="/error" component={Error}></Route>
          <Route path="/promociones1" component={PromocionesScreen}></Route>
          <Route path="/noticias-y-trucos" component={NotasHome}></Route>
          <Route path="/descargar-juegos" component={Descargaf}></Route>
          

          {/* /bonos/:id */}
          
          <PrivateRoute path="/bono/:id" component={BonosCompra}></PrivateRoute>
          <PrivateRoute
            path="/mi-cuenta"
            component={MicuentaHome}
          ></PrivateRoute>
          <PrivateRoute
            path="/editar-cuenta"
            component={EditarCuentaHome}
          ></PrivateRoute>
          <PrivateRoute
            path="/editar-contraseña"
            component={EditarContraseñaHome}
          ></PrivateRoute>
          <PrivateRoute path="/pedidos" component={PedidosHome}></PrivateRoute>
          <PrivateRoute path="/tickets" component={TicketsHome}></PrivateRoute>

          <Route exact path="*" component={Notfound}></Route>
        </Switch>
      );
    }
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Layouts>
            <Suspense fallback={<span><Spinner/></span>}>{loading}</Suspense>
          </Layouts>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;