import React from "react";
import Slider from "react-slick";
import SolidButton from "../componentes/buttons/SolidButton";
import Slide from "react-reveal/Slide";
import { Link } from "react-router-dom";
import {imagenes} from '../config/configuraciones'





function NuestroJuego() {

  const config = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const config_server = JSON.parse(localStorage.getItem("configServer"));
  
  const settings = config;

  return (
    <React.Fragment>
     

      <div className="nuestro-juegos">
        <h1>Nuestros Juegos</h1>
        {/* <div key="{i}" className="img-card"></div> */}
        <Slide left>
          <Slider {...settings} aria-hidden="true">
            <div>
              <div className="botones-img">
                <img
                  loading="lazy"
                  className="img-juego-2"
                  src={`${imagenes.urlImage}/imagen-1.png`}
                  alt="juegos-online"
                />
                  <img
                  loading="lazy"
                  className="img-juego-3"
                  src={`${imagenes.urlImage}/nueva-1.png`}
                  alt="juegos-online"
                />
              </div>

              <div className="botones">
                <a href={config_server.linkAndroid} download="Juego-Mbokaja">
                  <SolidButton txt="Descargar"></SolidButton>
                </a>
                <Link to="/maquina">
                  <SolidButton txt="Conocer más" type="primary"></SolidButton>
                </Link>
              </div>
            </div>
          </Slider>
        </Slide>
      </div>
    </React.Fragment>
  );
}

export default NuestroJuego;
