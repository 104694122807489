
  export const formatCurrency = (num, simbolo = "Pts") => {
    //console.log(num);
    // console.log(Number(num.toFixed(1)).toLocaleString() + "");
    return simbolo + " " + Number(num).toFixed(1).toLocaleString() + "";
  };
  
  export const priceNivel =(bronce, plata, oro, platino, nivel = null) => {
    if (nivel) {
      switch (nivel) {
        case "Bronce":
          return bronce;
          break;
        case "Plata":
          return plata;
          break;
        case "Oro":
          return oro;
          break;
        case "Platino":
          return platino;
          break;
          
      }
    } else {
      return bronce;
    }
  };


  

