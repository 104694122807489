import { useState, useEffect } from "react";
import swal from "sweetalert";

const useStateProducts = (API) => {

    const [products, setProducts] = useState([]);

    
   

    useEffect(() => {
        fetch(API, {
            method: 'GET'
        })
            .then((response) => response.json())
            .then((data) => {
                setProducts(data.data);

               
            }) .catch((error) => {
                swal({
                  title: "¡Ups !",
                  text: error.message,
                  icon: "error",
                  dangerMode: true,
                })
                .then(willDelete => {
                  if (willDelete) {
                   window.location.replace('/');
                   
                  }
                });
                console.log(error);
              });
    }, [API]);

    return products;
};

export default useStateProducts;

