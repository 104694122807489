import React from 'react';
import Navigation from '../layout/Navigation';
import Footer from '../layout/Footer';
// import serverComodin from "../config/serverComodin";
// import useStateConfig from "../hooks/useStateConfig";

// const API = serverComodin.baseURL + "/paginas/configuraciones";
const Layouts = ({children}) => {
    // const products = useStateConfig(API);
    // const dispatch = useDispatch();
    
    return (
    <React.Fragment>
        <Navigation />
        <div className="main-container">
        {children}
        </div>
        <Footer />


     
    </React.Fragment>

    )
};
export default Layouts;