import React from "react";
import SolidButton from "../componentes/buttons/SolidButton";
import Zoom from "react-reveal/Zoom";
import { Link } from "react-router-dom";
import {imagenes} from '../config/configuraciones'


function Tutoriales() {
  return (
    <div className="imagen-home-tutoriales">
      <div className="col-derecha-d">
        <Zoom>
          <img
            loading="lazy"
            src={`${imagenes.urlImage}/aprende.png`}
            className="juego-t-img"
            alt="Video tutorial app slot tragamonedas de apuestas ¿Cómo cargar la billetera de Mbokajá? "
          />
          {/* <img
            loading="lazy"
            src={`${imagenes.urlImage}/mbokaja.png`}
            className="juego-t-img-mbokaja"
            alt="¿Cómo cargar el monedero de Mbokajá? ¿Cómo cargar creditos Mbokajá?  "
          /> */}
          <Link to="/tutoriales" className="centrar">
            <SolidButton txt="Ver tutoriales"></SolidButton>
          </Link>
        </Zoom>
      </div>
      <img
        loading="lazy"
        src={`${imagenes.urlImage}/pina2.png`}
        className="juego-piña"
        alt="¿Como se juega Mbokajá?¿Como jugar Mbokajá?  
        "
      />
    </div>
  );
}

export default Tutoriales;
