import { useState, useEffect } from "react";
import swal from "sweetalert";

const useLogin = (API) => {

    const [login, setLogin] = useState([]);

    
   

    useEffect(() => {
        fetch(API, {
            method: 'GET'
        })
            .then((response) => response.json())
            .then((data) => {
                setLogin(data.data.productos);

               
            })
            .catch((error) => {
                swal({
                  title: "¡Ups !",
                  text: error.message,
                  icon: "error",
                  dangerMode: true,
                })
                .then(willDelete => {
                  if (willDelete) {
                   window.location.replace('/');
                   
                  }
                });
                console.log(error);
              });
    }, [API]);

    return login;
};

export default useLogin;