import React from "react";
import Flash from 'react-reveal/Flash';
import {imagenes} from '../config/configuraciones'

function Banderas() {
  return (
    
    <div className="bandera-home">
      <Flash>
      <img
        
        src={`${imagenes.urlImage}/bandera-cel.png`}
        className="bandera-img-cel"
        alt="Imagen de jackpot comodin ¡Con Club Origo ganás o ganás! "
      />
      <img
        
        src={`${imagenes.urlImage}/celu-b.png`}
        className="bandera-img-compu"
        alt="Imagen de jackpot comodin ¡Con Club Origo ganás o ganás! "
      />
      </Flash>
    </div>
    
  );
}

export default Banderas;
