import React from "react";
import '../App.css';
import { Link } from "react-router-dom";
import Sidebar2 from "./Sidebar2";
import Tada from 'react-reveal/Tada';
import {imagenes} from '../config/configuraciones'




class Sidebar extends React.Component {

  
    render () {
      const closeMenu = () => {
        document.querySelector(".sidebar").classList.remove("open");
      };

      return (<div>
        <nav className="nav1">
          <div className="contenedor-nav-sidebar-22">
            
            
            <button className="icono-button" onClick={closeMenu}>
                                <img
                  src="../../origo/icon-menu.png"
                  className="icono"
                  alt="Juegos-tragamonedas"
                />
              </button>

             
            
             
            
            <Tada>
              <Link to="/" onClick={closeMenu} >
                <img
                  src="../../origo/logo.png"
                  className="icon-logo"
                  alt="Logotipo Club Origo. El club de apuestas, máquinas slot tragamonedas y juegos de azar que más paga."
                />
              </Link>
              </Tada>
            

          </div>
        </nav>
        <div >
<Sidebar2/>
        

        
        </div>
        </div>

       
      );
    }
  }
  export default Sidebar;

