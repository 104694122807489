import React, { useEffect } from "react";




function ProductIdHome(props) {

const descarga = React.useRef(null);
 
  useEffect(() => {

    console.log( descarga.current)
     descarga.current.click();
     window.location.replace("/");
  }, []);



  return (
    <div>
      <a ref={descarga} href="https://clubcomodin.com/maquina" target="_blanck"></a>
      
     
    </div>
  );
}
export default ProductIdHome;
