import React from "react";
import SolidButton from "../componentes/buttons/SolidButton";
import Zoom from 'react-reveal/Zoom';
import {imagenes} from '../config/configuraciones'


function JuegoResp() {
  return (
    <div>
      <div className="imagen-home-juego">
        <img
          loading="lazy"
          src={`${imagenes.urlImage}/dados-2.png`}

          className="juego-dados"
          alt="juego responsable"
        />
        <div className="col-derecha-d">
          <Zoom>
          <img
            loading="lazy"
            src={`${imagenes.urlImage}/jugaresp.png`}

            className="juego-t-img"
            alt="juego responsable"
          />
<a href="https://juegoresponsable.com.py">
          <SolidButton txt="Ver más"></SolidButton>
          </a>
          </Zoom>
        </div>
      </div>
    </div>
  );
}

export default JuegoResp;
